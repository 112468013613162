/* Pain Point Section */
.pain-point .pain-point-img {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 600px;
}

.pain-point .pain-point-img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Adding shadow for depth */
}

/* General Styles (Desktop) */
.iceberg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; /* For proper positioning */
    max-width: 100%; /* Constrain to column width */
    overflow: hidden; /* Prevent overflow */
    gap: 10px; /* Slight spacing */
}

.iceberg-image img {
    max-width: 100%; /* Constrain to column width */
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.animated-list {
    position: absolute;
    right: 0; /* Start from the right edge */
    top: 9vh;
    max-width: 80%; /* Ensure it doesn’t overflow */
    max-height: 90%;
    font-size: clamp(14px, 2vw, 18px);
    background: rgba(255, 255, 255, 0.8); /* Add a slight background to distinguish */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
    z-index: 10; /* Ensure visibility */
    transform: translateX(150%); /* Hidden initially */
    transition: transform 0.5s ease;
}

.animated-list.active {
    transform: translateX(0); /* Slide into view */
}


  
/* About Us Section */
.about-us .about-us-img {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
  
.about-us .about-us-img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Adding shadow for depth */
}

.automation-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 50px 0;
    padding: 20px;
  }
  
  .text-container {
    max-width: 800px;
    margin-bottom: 20px;
  }
  
  .text-container p {
    font-size: 1.1rem;
    color: #7f8c8d;
  }
  
  .image-container {
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover {
    transform: scale(1.05); /* Slight zoom effect */
  }
  
  .automation-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
 
/* Added Value Section */
.added-value-item {
    transition: transform 0.3s ease;
    padding: 20px;
}
  
.added-value-item:hover {
    transform: translateY(-10px); /* Slight lift effect on hover */
}
  
.added-value-item h3 {
    margin-top: 10px;
    font-size: 1.4rem;
}
  
.added-value-item p {
    font-size: 1rem;
}

/* Service Cards */
.service-card {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
}
  
.service-card:hover {
    transform: translateY(-10px); /* Lift effect */
}

.service-card img {
    max-height: 250px;
    object-fit: cover;
}

.service-card h3 {
    margin-top: 15px;
    font-size: 1.5rem;
}

.service-card p {
    font-size: 1.1rem;
}
  
/* Call to Action Section */
.call-to-action {
    background-color: #176ac9; /*#176ac97d*/
    background-size: cover;
    background-position: center;
    position: relative;
    color: #8bb4e4;
}

/* .call-to-action .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(23, 106, 201, 0.5);
    z-index: 0;
} */

.call-to-action h2 {
    font-size: 2.5rem;
    font-weight: bold;
}

.call-to-action .cta-btn {
    background-color: white;
    font-size: 1.2rem;
    padding: 15px 30px;
    margin-top: 20px;
    border-radius: 30px;
}

.call-to-action .cta-btn:hover {
    background-color: #00aaff;
    color: white;
}
