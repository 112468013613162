@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure body and html take full height */
html, body {
  height: 100%;
  margin: 0;
  /* font-family: 'Josefin Sans', sans-serif; */
}



/* Set up the root container for flex layout */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content area should take up remaining space */
.content {
  flex: 1;
}

/* General Section Styling */
section {
  padding: 50px 0;
}

.container {
  /* padding-top: 2rem; */
  /* padding-bottom: 2rem; */
  padding: 1.5rem;
  max-width: 1140px;
  margin: 0 auto;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;    
  align-items: flex-end;  
}

p {
  font-size: 1.1rem;
  text-align: justify;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: #cccccc !important; 
  }
