.linkedin-carousel-container {
    max-width: 1200px;
    margin: 0 auto;
}

.slick-slide {
    display: flex;
    justify-content: center;
}

.carousel-item {
    padding: 20px;
}

.carousel-item .col {
    margin: 10px;
}

.linkedin-post-container iframe {
    border: 2px solid #ccc;
    border-radius: 10px;
}

.title-linkedin-icon {
    color: #0e76a8 !important;
    margin-left: 8px; /* Space between text and icon */
    transition: color 0.2s ease-in-out;
}

.title-linkedin-icon:hover {
    color: #1c13c1 !important;
}