.hero-section {
    /* height: 60vh; */
    min-height: 60vh;
    height: auto;

    display: flex;

    flex-direction: column;

    position: relative;
    
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: white;
}

.hero-video,
.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensures background stays behind content */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Adjust for better text visibility */
    z-index: 0;
  }

  .hero-content {
    position: relative;
    z-index: 1; /* Ensures content is above the overlay and video/image */
    text-align: center;
    padding: 0 20px;
  }

  .hero-logo {
    height: 150px;
    margin-bottom: 20px;
  }

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
  /* margin-bottom: 1rem; */
}

.hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

.learn-more-btn {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    /* padding: 10px 30px;
    font-size: 1.2rem;
    border-radius: 30px; */
}


.learn-more-btn:hover {
    /* background-color: #ccc; */
    /* color: black; */
    background-color: #00aaff;
    color: white;
    cursor: pointer; 
}


/* .hero-section h1,
.hero-section p,
.learn-more-btn,
.heroLogo{
    position: relative;
    z-index: 2;
} */

/* .hero-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-size: 1.25rem;
    margin-bottom: 2rem;
} */

@media (max-width: 768px) {
    .hero-section {
      overflow: auto;
      height: auto;
      padding: 40px 20px;
    }
  
    .hero-title {
      font-size: 1.8rem;
    }
  
    .hero-subtitle {
      font-size: 1rem;
    }
  
    .hero-logo {
      height: 100px; /* Scale down the logo for mobile */
    }
  
    .learn-more-btn {
      font-size: 0.9rem;
      padding: 10px 16px;
    }
  }
  