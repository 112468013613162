.about-us-page {
    font-family: 'Arial', sans-serif;
  }
  
  /* .hero-section {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
      url('assets/images/about/team.jpg');
    background-size: cover;
    background-position: center;
    color: white;
  } */
  
  .timeline {
    list-style-type: none;
    padding: 0;
  }
  
  .timeline li {
    position: relative;
    margin: 20px 0;
    padding-left: 40px;
    font-size: 1.1rem;
  }
  
  .timeline li:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 8px;
    width: 15px;
    height: 15px;
    background: #0d6efd;
    border-radius: 50%;
  }
  
  .carousel-item img {
    max-height: 250px;
    object-fit: cover;
  }
  
  .accordion-header {
    cursor: pointer;
  }
  
  .accordion-body {
    background-color: #f8f9fa;
  }
  
  .linkedin-posts-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .linkedin-post-container iframe {
    border: 2px solid #ccc;
    border-radius: 10px;
  }
  