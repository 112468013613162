.footer a {
    color: white; /* Change link color to white */
    text-decoration: none; /* Optional: remove underline */
  }

.footer a:hover {
color: #cccccc; 
}

/* Footer Styling */
.footer {
background-color: #343a40; /* Dark background color */
color: white;
display: flex;
justify-content: space-between; /* Align columns across the footer */
align-items: center; /* Center vertically if needed */
padding: 20px;
}

.footer .footer-left, 
.footer .footer-middle,
.footer .footer-right {
display: flex;
flex-direction: column;
align-items: center;
}

footer .footer-left figure {
display: flex;
flex-direction: column;
align-items: center;
}

footer .footer-left img {
max-width: 100%; /* Optional: Ensure it does not overflow */
height: auto;    /* Maintain aspect ratio */
}

.footer-logo {
width: 100px; /* Adjust logo size as needed */
margin-bottom: 10px;
}

.footer .text-center {
font-size: 0.9em;
}

.linkedin-icon {
color: white; /* Icon color */
margin-left: 8px; /* Space between text and icon */
transition: color 0.2s ease-in-out;
}

.linkedin-icon:hover {
color: #0e76a8 !important;
}

.text-muted {
color: #cccccc !important; 
}

/* Responsive Design: Mobile View */
@media (max-width: 768px) {
footer {
    flex-direction: column; /* Stack columns vertically on smaller screens */
    align-items: center; /* Center columns */
}

footer .footer-left,
footer .footer-middle,
footer .footer-right {
    flex: none; /* Reset flex on small screens */
    text-align: center;
    margin: 10px 0;
}

footer .footer-middle ul {
    padding-left: 0;
}

footer .fotter-middle li {
    margin-bottom: 8px;
}

footer .footer-right ul {
    padding-left: 0;
}
}