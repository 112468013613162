.offers-page {
    font-family: 'Arial', sans-serif;
  }
  
  .offer-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .offer-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    height: 200px;
    object-fit: cover;
  }
  
  .btn-learn-more {
    background-color: #007bff;
    border: none;
  }
  
  .btn-learn-more:hover {
    background-color: #0056b3;
  }
  
  
  
  /* General Section Styles */
.project-examples {
    padding: 50px 20px;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #2c3e50;
  }
  
  /* Project Item Styles */
  .project-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Start slightly below */
    transition: all 0.6s ease;
  }
  
  .project-item.reverse {
    flex-direction: row-reverse;
  }
  
  .project-item.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .project-image {
    width: 45%;
    border-radius: 10px;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-image:hover {
    transform: scale(1.05); /* Zoom in slightly */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .project-description {
    width: 45%;
    text-align: left;
  }
  
  .project-description h3 {
    font-size: 1.8rem;
    color: #34495e;
    margin-bottom: 10px;
  }
  
  .project-description p {
    font-size: 1rem;
    color: #7f8c8d;
    line-height: 1.6;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .project-item {
      flex-direction: column;
      text-align: center;
    }
  
    .project-item.reverse {
      flex-direction: column;
    }
  
    .project-image,
    .project-description {
      width: 100%;
    }
  }
  
  