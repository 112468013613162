.contact-page {
    font-family: 'Roboto', sans-serif;
  }
  
  .contact-page h1 {
    font-size: 2.5rem;
    color: #2c3e50;
  }
  
  .contact-page h3 {
    color: #2980b9;
  }
  
  .contact-page p {
    line-height: 1.8;
  }
  
  .map-frame {
    border-radius: 8px;
  }
  
  .card {
    border: none;
    border-radius: 10px;
  }
  